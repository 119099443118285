'use client';
export const LogoIsuzu: React.FC = () => (
  <svg
    width="104"
    height="18"
    viewBox="0 0 104 18"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="ISUZU"
    role="img"
  >
    <path
      d="M103.317 0h-4.385a.73.73 0 0 0-.738.722v10.776c0 1.185-1.061 2.197-2.278 2.185l-4.36.012c-1.215-.012-2.282-1.011-2.272-2.197L89.276.728A.715.715 0 0 0 88.551 0h-4.378c-.491 0-.813.342-.813.722v12.543c0 2.416 2.037 4.399 4.455 4.465l11.833.014a4.484 4.484 0 0 0 4.354-4.479V.722C104 .342 103.701 0 103.317 0zM64.765 12.464 76.63 8.55c1.884-.503 3.184-2.114 3.182-4.166C79.812 1.947 77.787 0 75.341 0H59.392c-.406 0-.768.342-.768.728v2.527c0 .362.362.708.768.697h13.545c.301 0 .538.348.534.663 0 .249-.224.469-.455.576L61.034 8.993c-1.722.56-3.117 2.225-3.117 4.275 0 2.416 1.983 4.483 4.401 4.473l15.971.008c.356-.018.778-.412.778-.833v-2.509c0-.402-.422-.708-.778-.708H64.765c-.473-.012-.675-.279-.667-.572-.002-.344.325-.528.667-.661zM54.213 0h-4.378a.78.78 0 0 0-.768.728l.004 10.772c0 1.185-1.039 2.197-2.224 2.185l-4.395.012a2.2 2.2 0 0 1-2.226-2.197V.722c0-.38-.335-.722-.764-.722H34.99c-.384 0-.752.342-.75.722v12.543c0 2.416 2.048 4.399 4.486 4.473h11.857a4.47 4.47 0 0 0 4.326-4.473V.728C54.915.342 54.606 0 54.213 0zm-25.91 8.993L16.296 5.191c-.23-.105-.497-.317-.481-.576 0-.315.251-.651.546-.663l13.543.012a.706.706 0 0 0 .703-.708l.008-2.527C30.606.342 30.317 0 29.904 0H13.962c-2.45.008-4.461 1.947-4.461 4.386 0 2.052 1.288 3.663 3.17 4.166l11.916 3.915c.299.133.598.321.598.659-.002.285-.172.564-.606.56l-13.531.012c-.406-.012-.839.307-.839.701v2.517c0 .422.431.833.835.833h15.914c2.45-.008 4.392-2.066 4.395-4.483a4.52 4.52 0 0 0-3.052-4.273zM5.126 0H.829C.346 0-.002.342 0 .796l.004 16.175c-.004.408.342.778.825.77l4.297.008c.441-.008.823-.36.817-.778L5.947.796A.81.81 0 0 0 5.126 0z"
      fill="currentColor"
    />
  </svg>
);

LogoIsuzu.displayName = 'LogoIsuzu';
