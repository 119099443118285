'use client';
export const IconCarCv: React.FC = () => (
  <svg
    width="62"
    height="30"
    viewBox="0 0 62 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#444" d="M37.967 20.86H22.395v4.964h15.572V20.86Z" />
    <path
      fill="#444"
      d="M40.425 20.86H31.41v4.964h6.966c0-.15-.038-.338-.038-.489 0-1.805.852-3.385 2.087-4.476Zm3.517-.02c-2.266 0-4.12 1.866-4.12 4.147s1.854 4.148 4.12 4.148 4.12-1.867 4.12-4.148c0-2.28-1.854-4.147-4.12-4.147Zm0 6.325a2.161 2.161 0 0 1-2.163-2.178 2.16 2.16 0 0 1 2.163-2.177c1.201 0 2.163.968 2.163 2.177 0 1.21-.962 2.178-2.163 2.178ZM16.444 20.84c-2.266 0-4.12 1.866-4.12 4.147s1.854 4.148 4.12 4.148 4.12-1.867 4.12-4.148c0-2.28-1.854-4.147-4.12-4.147Zm0 6.325a2.161 2.161 0 0 1-2.163-2.178 2.16 2.16 0 0 1 2.163-2.177c1.201 0 2.163.968 2.163 2.177 0 1.21-.962 2.178-2.163 2.178Zm37.214-6.325c-2.266 0-4.12 1.866-4.12 4.147s1.854 4.148 4.12 4.148c2.265 0 4.12-1.867 4.12-4.148 0-2.28-1.855-4.147-4.12-4.147Zm0 6.325a2.161 2.161 0 0 1-2.163-2.178 2.16 2.16 0 0 1 2.163-2.177c1.201 0 2.163.968 2.163 2.177 0 1.21-.962 2.178-2.163 2.178Zm3.605-6.29c1.133 1.002 1.82 2.488 1.82 4.113 0 .138 0 .31-.035.45H62v-4.563h-4.737ZM61.735 1h-39.34v18.204h39.34V1Z"
    />
    <path
      fill="#444"
      d="M18.126 3.867H10.23s-2.78.07-2.952.208c0 0-1.682 3.87-3.124 9.124h-.412l-.55.795v.345H.997s-.344.035-.378-.345v-.622l.137-.208h.48v-3.076C1.1 9.812.619 9.916.619 9.916 0 10.019 0 12.058 0 12.61c0 .553.618.553.618.553H.412s-.137.173-.137.311v.657s.137.588.686.519h2.197c-.034.103-.103.207-.103.276V25.02c.035.414.344.345.344.345H5.56v-.311l.035.069 4.428.069s.584-.415.584-.518l.034-.311.069-.208s1.304-3.629 3.776-4.251c0 0 .824-.207 1.34-.207h4.325s.995 0 .961-.726v-3.284l.103-6.877s-.137-2.385-.55-3.042c0 0-1.716-1.9-2.54-1.9Zm-.103 2.558-.206 4.804c-.034.726-.686.622-.686.622h-.275c-3.055.346-9.406 2.938-9.406 2.938-.481.207-.481-.311-.481-.311-.034-4.079 1.064-6.913 1.27-7.638.206-.726 1.167-.934 1.167-.934 1.27-.172 7.965-.138 7.965-.138.687.104.652.657.652.657Z"
    />
  </svg>
);
IconCarCv.displayName = 'IconCarCv';
